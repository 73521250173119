export const path = [
  "/surveysample",
  "/basicsurvey",
  "/survey",
  "/result",
  "/complete",
  "/2ndmanual",
  "/meeting",
  "/meet",
  "/inputvisit",
  "/visit",
  "/visitadmin",
  "/adminlogin",
  "/surveylist",
  "/applylist",
  "/adminregist",
  "/login",
  "/tv",
  "/inputjob",
  "/beforevisit",
  "/updatejob",
  "/totaltable",
  "/after",
  "/inputafter",
  "/subscribe",
  "/subscribegenerator",
  "/sublist",
  "/subdetail",
  "/adagree",
  "/test",
  "/adgenerator",
  "/agreeresult",
  "/agreelist",
  "/macbook",
  "/giftadmin",
  "/promo",
  "/half",
  "/companysurvey",
  "/companylinkgen",
  "/admin",
  "/surveylink",
  "/printresult",
  "/papersurvey",
];
