export const modules = {
  toolbar: {
    container: [
      [{ font: [] }],
      [{ size: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, "link"],
      [
        {
          color: [],
        },
        { background: [] },
      ],
    ],
  },
};

export const sectors = [
  // 업,직종 DB
  ["전체", 0],
  ["단순상담", 1],
  ["텔레마케팅", 2],
  ["일반사무", 3],
  ["영업지원", 4],
  ["웹디자인", 5],
  ["총무", 6],
];

export const cities1 = [
  // 지역DB (시/도)
  ["전국", 0],
  ["서울", 1],
  ["인천", 2],
  ["세종", 3],
  ["대전", 4],
  ["부산", 5],
  ["울산", 6],
  ["대구", 7],
  ["광주", 8],
  ["제주", 9],
  ["강원도", 10],
  ["경기도", 11],
  ["충청북도", 12],
  ["충청남도", 13],
  ["경상북도", 14],
  ["경상남도", 15],
  ["전라북도", 16],
  ["전라남도", 17],
];

export const cities2 = [
  // 지역DB (시/군/구)
  [["전체", 0]], //전국
  [
    ["전체", 0],
    ["강남구", 1],
    ["강동구", 2],
    ["강북구", 3],
    ["강서구", 4],
    ["관악구", 5],
    ["광진구", 6],
    ["구로구", 7],
    ["금천구", 8],
    ["노원구", 9],
    ["도봉구", 10],
    ["동대문구", 11],
    ["동작구", 12],
    ["마포구", 13],
    ["서대문구", 14],
    ["서초구", 15],
    ["성동구", 16],
    ["성북구", 17],
    ["송파구", 18],
    ["양천구", 19],
    ["영등포구", 20],
    ["용산구", 21],
    ["은평구", 22],
    ["종로구", 23],
    ["중구", 24],
    ["중랑구", 25],
  ], // 서울
  [
    ["전체", 0],
    ["강화군", 1],
    ["계양구", 2],
    ["남동구", 3],
    ["동구", 4],
    ["미추홀구", 5],
    ["부평구", 6],
    ["서구", 7],
    ["연수구", 8],
    ["옹진군", 9],
    ["중구", 10],
  ], // 인천
  [["전체", 0]], // 세종
  [
    ["전체", 0],
    ["대덕구", 1],
    ["동구", 2],
    ["서구", 3],
    ["유성구", 4],
    ["중구", 5],
  ], // 대전
  [
    ["전체", 0],
    ["강서구", 1],
    ["금정구", 2],
    ["기장군", 3],
    ["유성구", 4],
    ["남구", 5],
    ["동구", 6],
    ["동래구", 7],
    ["부산진구", 8],
    ["북구", 9],
    ["사상구", 10],
    ["사하구", 11],
    ["서구", 12],
    ["수영구", 13],
    ["연제구", 14],
    ["영도구", 15],
    ["중구", 16],
    ["해운대구", 17],
  ], // 부산
  [
    ["전체", 0],
    ["남구", 1],
    ["동구", 2],
    ["북구", 3],
    ["유성구", 4],
    ["울주군", 5],
    ["중구", 6],
  ], // 울산
  [
    ["전체", 0],
    ["남구", 1],
    ["달서구", 2],
    ["달성군", 3],
    ["동구", 4],
    ["북구", 5],
    ["서구", 6],
    ["수성구", 7],
    ["중구", 8],
  ], // 대구
  [
    ["전체", 0],
    ["광산구", 1],
    ["남구", 2],
    ["동구", 3],
    ["북구", 4],
    ["서구", 5],
  ], // 광주
  [
    ["전체", 0],
    ["서귀포시", 1],
    ["제주시", 2],
  ], // 제주
  [
    ["전체", 0],
    ["강릉시", 1],
    ["고성군", 2],
    ["동해시", 3],
    ["삼척시", 4],
    ["속초시", 5],
    ["양구군", 6],
    ["양양군", 7],
    ["영월군", 8],
    ["원주시", 9],
    ["인제군", 10],
    ["정선군", 11],
    ["철원군", 12],
    ["춘천시", 13],
    ["태백시", 14],
    ["평창군", 15],
    ["홍천군", 16],
    ["화천군", 17],
    ["횡성군", 18],
  ], // 강원도
  [
    ["전체", 0],
    ["가평군", 1],
    ["고양시 덕양구", 2],
    ["고양시 일산동구", 3],
    ["고양시 일산서구", 4],
    ["과천시", 5],
    ["광명시", 6],
    ["광주시", 7],
    ["구리시", 8],
    ["군포시", 9],
    ["김포시", 10],
    ["남양주시", 11],
    ["동두천시", 12],
    ["부천시", 13],
    ["성남시 분당구", 14],
    ["성남시 수정구", 15],
    ["성남시 중원구", 16],
    ["수원시 권선구", 17],
    ["수원시 영통구", 18],
    ["수원시 장안구", 19],
    ["수원시 팔달구", 20],
    ["시흥시", 21],
    ["안산시 단원구", 22],
    ["안산시 상록구", 23],
    ["안성시", 24],
    ["안양시 동안구", 25],
    ["안양시 만안구", 26],
    ["양주시", 27],
    ["양평군", 28],
    ["여주시", 29],
    ["연천군", 30],
    ["오산시", 31],
    ["용인시 기흥구", 32],
    ["용인시 수지구", 33],
    ["용인시 처인구", 34],
    ["의왕시", 35],
    ["의정부시", 36],
    ["이천시", 37],
    ["파주시", 38],
    ["평택시", 39],
    ["포천시", 40],
    ["하남시", 41],
    ["화성시", 42],
  ], // 경기도
  [
    ["전체", 0],
    ["괴산군", 1],
    ["단양군", 2],
    ["보은군", 3],
    ["영동군", 4],
    ["옥천군", 5],
    ["음성군", 6],
    ["제천시", 7],
    ["증평군", 8],
    ["진천군", 9],
    ["청주시 상당구", 10],
    ["청주시 서원구", 11],
    ["청주시 청원구", 12],
    ["청주시 흥덕구", 13],
    ["충주시", 14],
  ], // 충청북도
  [
    ["전체", 0],
    ["계룡시", 1],
    ["공주시", 2],
    ["금산군", 3],
    ["논산시", 4],
    ["당진시", 5],
    ["보령시", 6],
    ["부여군", 7],
    ["서산시", 8],
    ["서천군", 9],
    ["아산시", 10],
    ["예산군", 11],
    ["천안시 동남구", 12],
    ["천안시 서북구", 13],
    ["청양군", 14],
    ["태안군", 15],
    ["홍성군", 16],
  ], // 충청남도
  [
    ["전체", 0],
    ["경산시", 1],
    ["경주시", 2],
    ["고령군", 3],
    ["구미시", 4],
    ["군위군", 5],
    ["김천시", 6],
    ["문경시", 7],
    ["봉화군", 8],
    ["상주시", 9],
    ["성주군", 10],
    ["안동시", 11],
    ["영덕군", 12],
    ["영양군", 13],
    ["영주시", 14],
    ["영천시", 15],
    ["예천군", 16],
    ["울릉군", 17],
    ["울진군", 18],
    ["의성군", 19],
    ["청도군", 20],
    ["청송군", 21],
    ["칠곡군", 22],
    ["포항시 남구", 23],
    ["포항시 북구", 24],
  ], // 경상북도
  [
    ["전체", 0],
    ["거제시", 1],
    ["거창군", 2],
    ["고성군", 3],
    ["김해시", 4],
    ["남해군", 5],
    ["밀양시", 6],
    ["사천시", 7],
    ["산청군", 8],
    ["양산시", 9],
    ["의령군", 10],
    ["진주시", 11],
    ["창녕군", 12],
    ["창원시 마산합포구", 13],
    ["창원시 마산회원구", 14],
    ["창원시 성산구", 15],
    ["창원시 의창구", 16],
    ["창원시 진해구", 17],
    ["통영시", 18],
    ["하동군", 19],
    ["함안군", 20],
    ["함양군", 21],
    ["합천군", 22],
  ], // 경상남도
  [
    ["전체", 0],
    ["고창군", 1],
    ["군산시", 2],
    ["김제시", 3],
    ["남원시", 4],
    ["무주군", 5],
    ["부안군", 6],
    ["순창군", 7],
    ["완주군", 8],
    ["익산시", 9],
    ["임실군", 10],
    ["장수군", 11],
    ["전주시 덕진구", 12],
    ["전주시 완산구", 13],
    ["정읍시", 14],
    ["진안군", 15],
  ], // 전라북도
  [
    ["전체", 0],
    ["강진군", 1],
    ["고흥군", 2],
    ["곡성군", 3],
    ["광양시", 4],
    ["구례군", 5],
    ["나주시", 6],
    ["담양군", 7],
    ["목포시", 8],
    ["무안군", 9],
    ["보성군", 10],
    ["순천시", 12],
    ["신안군", 13],
    ["여수시", 14],
    ["영광군", 15],
    ["영암군", 16],
    ["완도군", 17],
    ["장성군", 18],
    ["장흥군", 19],
    ["진도군", 20],
    ["함평군", 21],
    ["해남군", 22],
    ["화순군", 23],
  ], // 전라남도
];

export const salaries = [
  ["시급", 0],
  ["일급", 1],
  ["주급", 2],
  ["월급", 3],
  ["연봉", 4],
];

export const careers = [
  ["전체", 0],
  ["신입", 1],
  ["경력", 2],
  ["경력무관", 3],
];

export const welfares = [
  ["국민연금", 0],
  ["고용보험", 1],
  ["산재보험", 2],
  ["건강보험", 3],
  ["연차", 4],
  ["월차", 5],
  ["정기휴가", 6],
  ["인센티브제", 7],
  ["정기보너스", 8],
  ["퇴직금", 9],
  ["퇴직연금", 10],
  ["장기근속자 포상", 11],
  ["우수사원 표창/포상", 12],
  ["야간근로수당", 13],
  ["휴일근로수당", 14],
  ["연월차수당", 15],
  ["장기근속수당", 16],
  ["위험수당", 17],
  ["연장근로수당", 18],
  ["기숙사운영", 19],
  ["명절 귀향비 지급", 20],
  ["각종 경조금", 21],
  ["경조휴가제", 22],
  ["생활편의 지원", 23],
  ["조식제공", 24],
  ["중식제공", 25],
  ["석식제공", 26],
  ["근무복 지급", 27],
  ["통근버스 운행", 28],
  ["야간교통비 지급", 29],
  ["차량유류보조금", 30],
  ["주차비지원", 31],
  ["주차가능", 32],
];

export const minSal = {
  // 최저임금
  hour: 9620,
  week: 461760,
  month: 2010580,
};
